import {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useRef,
    useContext,
} from 'react';
import Loading from '../components/Loading';
import { measureInPx } from '../util/Measure';
import TemplateMiniature from './TemplateMiniature';
import * as TemplatesService from '../services/TemplatesService';
import { OrientationEnum, DocumentTypeEnum } from 'plataforma-braille-common';
import ScrollPagination from '../components/ScrollPagination';
import './DocumentModalTemplates.scss';
import ErrorLoading from '../components/ErrorLoading';
import {
    EditorCopy,
    EditorCopy2x,
    EditorEdit,
    EditorEdit2x,
    EditorRemove,
    EditorRemove2x,
} from '../components/images';
import PropTypes from 'prop-types';
import { EnvironmentContext } from '../contexts/EnviromentContext';

/**
 * @typedef {object} DocumentModalTemplatesParams
 * @property {function(TemplateDto)} onShowTemplateDetail
 * @property {function()} onSelectTemplate
 * @property {function(TemplateDto)} onEdit
 * @property {function(TemplateDto)} onRemove
 * @property {function(TemplateDto)} onDuplicate
 * @property {boolean} freeze
 */

/**
 * @typedef {object} DocumentModalTemplatesFunctions
 * @property {function()} fetch
 * @property {function()} reset
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<DocumentModalTemplatesParams>>}
 */
const DocumentModalTemplates = forwardRef(
    (
        {
            onShowTemplateDetail,
            onSelectTemplate,
            onEdit,
            onRemove,
            onDuplicate,
            freeze,
        },
        ref,
    ) => {
        /**
         * @type {TemplateDto[] | null}
         */
        const initialTemplates = null;
        const [templates, setTemplates] = useState(initialTemplates);
        const [loadingTemplates, setLoadingTemplates] = useState(false);
        const [errorLoadingTemplates, setErrorLoadingTemplates] =
            useState(true);
        const singleClickTimeRef = useRef(null);

        const { showPopup } = useContext(EnvironmentContext);

        const scrollControlInitialState = {
            records: 0,
            pageSize: 0,
        };

        const [scrollControl, setScrollControl] = useState(
            scrollControlInitialState,
        );

        /**
         * @type {{search: string | null, page: number, order: null}}
         */ const filterInitialState = {
            search: null,
            order: null,
            page: 0,
        };

        const [filter, setFilter] = useState(filterInitialState);

        const templatesScrollRef = useRef();

        async function fetchTemplates() {
            try {
                setLoadingTemplates(true);
                setErrorLoadingTemplates(false);
                const response = await TemplatesService.getTemplates(filter);
                setTemplates((templates) => {
                    templates = templates ?? [];
                    const ids = new Set(templates.map((item) => item.id));
                    return templates.concat(
                        response['records'].filter((item) => {
                            return !ids.has(item.id);
                        }),
                    );
                });
                setScrollControl({
                    records: response['records'].length,
                    pageSize: response['pageSize'],
                });
                setLoadingTemplates(false);
            } catch (e) {
                console.error('Error to fetch templates.', e);
                setLoadingTemplates(false);
                setErrorLoadingTemplates(true);
            }
        }

        useImperativeHandle(ref, () => ({
            fetch: () => {
                setTemplates(null);
                setScrollControl(scrollControlInitialState);
                setFilter((filter) => {
                    filter.page = 0;
                    return { ...filter };
                });
            },
            reset: () => {
                setTemplates(null);
                setLoadingTemplates(false);
                setScrollControl(scrollControlInitialState);
                setFilter(filterInitialState);
            },
        }));

        useEffect(() => {
            if (freeze) return;
            // noinspection JSIgnoredPromiseFromCall
            fetchTemplates();
        }, [filter, freeze]);

        let miniatures = [];
        let globalMaxWidth = 0;

        function showPopupMenu(event, template) {
            /**
             * @type {PopupMenuItem[]}
             */
            const menuItems = [];
            menuItems.push({
                // I18N
                description: 'Duplicar',
                icon: [EditorCopy, EditorCopy2x],
                action: () => onDuplicate(template),
            });
            menuItems.push({
                // I18N
                description: 'Editar',
                icon: [EditorEdit, EditorEdit2x],
                action: () => onEdit(template),
            });
            menuItems.push({
                // I18N
                description: 'Remover',
                icon: [EditorRemove, EditorRemove2x],
                action: () => onRemove(template),
            });

            showPopup(event.target, menuItems);
        }

        if (templates) {
            for (const template of templates) {
                const width =
                    template.pageOrientation === OrientationEnum.LANDSCAPE
                        ? template.pageHeight
                        : template.pageWidth;
                const templateWidthPx = measureInPx(
                    `${width}${template.pageMeasure}`,
                );
                if (templateWidthPx > globalMaxWidth)
                    globalMaxWidth = templateWidthPx;
            }

            for (const template of templates) {
                const params = {
                    ...template,
                    globalMaxWidth,

                    maxWidth: 190,
                };

                miniatures.push(
                    <TemplateMiniature
                        className={'template'}
                        key={template.id}
                        onClick={() => {
                            clearTimeout(singleClickTimeRef.current);
                            singleClickTimeRef.current = setTimeout(() => {
                                onSelectTemplate(template);
                            }, 300);
                        }}
                        onDoubleClick={() => {
                            clearTimeout(singleClickTimeRef.current);
                            onShowTemplateDetail(template);
                        }}
                        onExpandClick={() => {
                            clearTimeout(singleClickTimeRef.current);
                            onShowTemplateDetail(template);
                        }}
                        onShowMenuClick={(e) => showPopupMenu(e, template)}
                        onlyImages={
                            template.type === DocumentTypeEnum.IMAGE_DESCRIPTION
                        }
                        {...params}
                    />,
                );
            }
        }

        const initialLoading = loadingTemplates && !templates;
        const noTemplatesFound =
            !loadingTemplates && templates && !templates.length;

        const errorLoading = () => {
            return (
                <ErrorLoading
                    onTryAgain={() => {
                        setFilter({ ...filter });
                    }}
                />
            );
        };

        const scrollPagination = () => {
            if (errorLoadingTemplates) {
                return errorLoading();
            } else if (
                scrollControl.records >= scrollControl.pageSize &&
                templatesScrollRef.current
            ) {
                return (
                    <ScrollPagination
                        scrollElement={templatesScrollRef.current}
                        suspended={loadingTemplates}
                        onPageRequested={() => {
                            // if it has no template, is the first page, so no increment
                            const page =
                                (filter.page ?? 0) + (templates ? 1 : 0);
                            setFilter((filter) => {
                                filter.page = page;
                                return { ...filter };
                            });
                        }}
                    />
                );
            } else {
                return <></>;
            }
        };

        if (!miniatures.length && errorLoadingTemplates) {
            return (
                <div className={'table-container'}>
                    <div className={'status'}>{errorLoading()}</div>
                </div>
            );
        }

        return (
            <>
                {initialLoading && (
                    <div className={'table-container'}>
                        <div className={'status'}>
                            <Loading />
                        </div>
                    </div>
                )}

                {!initialLoading && noTemplatesFound && (
                    <div className={'table-container'}>
                        {/*I18N*/}
                        <div className={'status'}>
                            {'Nenhuma predefinição encontrada'}
                        </div>
                    </div>
                )}

                <div
                    className={'table-container'}
                    style={{
                        display: `${initialLoading || noTemplatesFound ? 'none' : 'unset'}`,
                    }}
                >
                    <div ref={templatesScrollRef} className={'scroll'}>
                        <div className={'grid'}>
                            {miniatures}
                            {scrollPagination()}
                        </div>
                    </div>
                </div>
            </>
        );
    },
);

DocumentModalTemplates.displayName = 'DocumentModalTemplates';

DocumentModalTemplates.propTypes = {
    onShowTemplateDetail: PropTypes.func.isRequired,
    onSelectTemplate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    freeze: PropTypes.bool.isRequired,
};

export default DocumentModalTemplates;
