import {
    addParagraphBreakAboveSel,
    addParagraphBreakBellowSel,
} from '../core/PageManipulation';
import { removeEditorElementSel } from '../core/EditorElements';
import {
    getBrailleDocument,
    isInsideEditorElementImage,
    isInsideEditorElementImageLayout,
} from '../core/EditorUtil';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
// eslint-disable-next-line no-unused-vars
export function createContextMenu(editor, params) {
    editor.ui.registry.addMenuItem(
        'customContextMenuEditorElementImageAddParagraphBreakAbove',
        {
            icon: 'action-prev',
            // I18N
            text: 'Inserir parágrafo acima',
            onAction: function () {
                addParagraphBreakAboveSel(editor);
            },
            onSetup: (api) => {
                api.setEnabled(!getBrailleDocument(editor).readOnly);
            },
        },
    );
    editor.ui.registry.addMenuItem(
        'customContextMenuEditorElementImageAddParagraphBreakBellow',
        {
            icon: 'action-next',
            // I18N
            text: 'Inserir parágrafo abaixo',
            onAction: function () {
                addParagraphBreakBellowSel(editor);
            },
            onSetup: (api) => {
                api.setEnabled(!getBrailleDocument(editor).readOnly);
            },
        },
    );
    editor.ui.registry.addMenuItem(
        'customContextMenuEditorElementImageRemove',
        {
            icon: 'remove',
            // I18N
            text: 'Remover',
            onAction: function () {
                removeEditorElementSel(editor);
            },
            onSetup: (api) => {
                api.setEnabled(!getBrailleDocument(editor).readOnly);
            },
        },
    );

    editor.ui.registry.addContextMenu('image', {
        update: function (element) {
            if (
                isInsideEditorElementImage(element) ||
                isInsideEditorElementImageLayout(element)
            ) {
                return [
                    'customContextMenuEditorElementImageAddParagraphBreakAbove',
                    'customContextMenuEditorElementImageAddParagraphBreakBellow',
                    '|',
                    'customContextMenuEditorElementImageRemove',
                ];
            }
            return '';
        },
    });
}
