import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { imageDescription } from '../services/DocumentService';
import {
    extractImgData,
    getClosestPage,
} from './editor-mods/modules/core/EditorUtil';
import { getImageUrlDataFromBackgroundImage } from 'plataforma-braille-common';
import '../components/FloatDialog.scss';
import FloatDialog from '../components/FloatDialog';

/**
 * @param editor {EditorCustom}
 * @param attachEditorElement {HTMLElement}
 * @param uploadImages {function():Promise}
 * @returns {JSX.Element}
 * @constructor
 */
function ButtonRetrieveDescriptionAi({
    editor,
    attachEditorElement,
    uploadImages,
}) {
    /**
     * @type {React.MutableRefObject<FloatDialogFunctions | undefined>}
     */
    const floatDialogRef = useRef();
    const attachEditorElementImageRef = useRef(attachEditorElement);
    const { setLoading, backendConnectionError } =
        useContext(EnvironmentContext);

    useEffect(() => {
        attachEditorElementImageRef.current = attachEditorElement;
    }, [attachEditorElement]);

    async function retrieveImageDescription() {
        if (!attachEditorElementImageRef.current) return;
        const editorImageElement = attachEditorElementImageRef.current;
        const infoDescription =
            editorImageElement.querySelector('.info-description');
        const loadingMsg = 'Gerando descrição da imagem...';
        let imageData = getImageUrlDataFromBackgroundImage(
            editorImageElement.style.backgroundImage,
        );
        if (!imageData) {
            console.warn(
                'No image data found in image element.',
                editorImageElement,
            );
            return;
        }
        imageData = await extractImgData(imageData);
        if (imageData.base64) {
            await uploadImages();
            imageData = getImageUrlDataFromBackgroundImage(
                editorImageElement.style.backgroundImage,
            );
            imageData = await extractImgData(imageData);
            setLoading(true, false, loadingMsg);
        }
        imageData = imageData.url;
        editor.undoManager.transact(async () => {
            setLoading(true, false, loadingMsg);
            try {
                infoDescription.innerText = await imageDescription(imageData);
                infoDescription.setAttribute('contentEditable', 'true');
                editorImageElement.removeAttribute('data-loading-background');
                floatDialogRef.current?.recalculePosition();
                attachEditorElementImageRef.current.removeAttribute(
                    'data-loading-background',
                );
                editor.custom.coreModule.updatePage(
                    getClosestPage(editorImageElement),
                    true,
                );
            } catch (e) {
                backendConnectionError(
                    'Fail to retrieve image description.',
                    e,
                );
            } finally {
                setLoading(false);
            }
        });
    }

    const hasImage = !!attachEditorElement?.style?.backgroundImage;

    const show =
        hasImage &&
        attachEditorElementImageRef.current?.getAttribute(
            'data-loading-background',
        ) !== 'true';

    return (
        <FloatDialog
            ref={floatDialogRef}
            editor={editor}
            attachEditorElement={show ? attachEditorElement : null}
        >
            <a onClick={retrieveImageDescription}>
                {/*I18N*/}
                {'Usar IA para gerar descrição'}
            </a>
        </FloatDialog>
    );
}

ButtonRetrieveDescriptionAi.displayName = 'ButtonRetrieveDescriptionAi';

ButtonRetrieveDescriptionAi.propTypes = {
    editor: PropTypes.any,
    attachEditorElement: PropTypes.any,
    uploadImages: PropTypes.func.isRequired,
};

export default ButtonRetrieveDescriptionAi;
