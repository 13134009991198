import Miniature from './Miniature';
import './TemplateMiniature.scss';
import PropTypes from 'prop-types';

function TemplateMiniature({
    id,
    name,
    pageWidth,
    pageHeight,
    pageMeasure,
    showDetails,
    onlyImages,
    ...props
}) {
    function details() {
        // I18N
        const pageDimensionsStr = `Tamanho ${pageWidth}x${pageHeight}${pageMeasure?.toLowerCase()}`;

        if (showDetails ?? true) {
            return (
                <div className={'template-details'}>
                    <div className={'template-name'} title={name}>
                        {name}
                    </div>
                    <div className={'template-info'} title={pageDimensionsStr}>
                        {/*I18N*/}
                        {pageDimensionsStr}
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    function contents() {
        if (onlyImages) {
            const array = [];
            for (let i = 0; i < 3; i++) {
                array.push(
                    <div key={`${id}_${i}`}>
                        <editor-element type={'image'}>
                            <div className={'info-container'}>
                                <div
                                    className={'info-legend'}
                                    data-placeholder={'Legenda: '}
                                    id={'element1967'}
                                >
                                    &nbsp;
                                </div>
                                <div
                                    className={'info-description'}
                                    data-placeholder={'Descrição: '}
                                    id={'element2169'}
                                >
                                    {
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed'
                                    }
                                    {
                                        'do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                                    }
                                </div>
                            </div>
                            <div className={'page-container'}>
                                <div
                                    className={'page-number'}
                                    data-placeholder={'Pág.: '}
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </editor-element>
                        <br />
                        <br />
                    </div>,
                );
            }

            return array;
        } else {
            return (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <strong>{'Lorem ipsum'}</strong>
                    </div>
                    <br />
                    {
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean sed adipiscing diam donec adipiscing. Diam vel quam elementum pulvinar etiam non. Vitae sapien pellentesque habitant morbi tristique. Lobortis feugiat vivamus at augue eget arcu dictum varius. Lorem ipsum dolor sit amet. Nisi porta lorem mollis aliquam. Faucibus ornare suspendisse sed nisi lacus sed viverra. Et malesuada fames ac turpis egestas integer eget aliquet. Cursus metus aliquam eleifend mi. Id aliquet risus feugiat in ante. Faucibus purus in massa tempor nec feugiat nisl pretium fusce.'
                    }
                    <br />
                    <br />
                    {
                        'Tellus id interdum velit laoreet id donec ultrices. Scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis. Sit amet nisl suscipit adipiscing bibendum est ultricies integer. Magna etiam tempor orci eu lobortis. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Lacinia at quis risus sed vulputate odio ut enim blandit. Sit amet consectetur adipiscing elit ut aliquam purus. Sed blandit libero volutpat sed cras ornare arcu. Tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Ultrices eros in cursus turpis massa tincidunt. Nisl nisi scelerisque eu ultrices vitae auctor. Varius morbi enim nunc faucibus a pellentesque sit.'
                    }
                    <br />
                    <br />
                    {
                        'Suscipit adipiscing bibendum est ultricies integer. A condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Gravida neque convallis a cras. Mauris rhoncus aenean vel elit. Aliquam id diam maecenas ultricies. Ut porttitor leo a diam. Etiam dignissim diam quis enim lobortis scelerisque fermentum dui. At imperdiet dui accumsan sit amet nulla facilisi. Eu non diam phasellus vestibulum. Et tortor consequat id porta nibh venenatis cras. Orci eu lobortis elementum nibh tellus molestie nunc non. Habitant morbi tristique senectus et netus et malesuada fames.'
                    }
                    <br />
                    <br />
                    {
                        'Ornare suspendisse sed nisi lacus sed viverra. Arcu non odio euismod lacinia at. Et magnis dis parturient montes nascetur. Vel fringilla est ullamcorper eget nulla. Nisi quis eleifend quam adipiscing vitae. Tortor pretium viverra suspendisse potenti nullam ac. Lectus sit amet est placerat. Mauris rhoncus aenean vel elit scelerisque. Facilisis leo vel fringilla est ullamcorper eget nulla. Id nibh tortor id aliquet lectus. Felis bibendum ut tristique et. Dolor purus non enim praesent elementum facilisis leo vel. Fames ac turpis egestas maecenas. Sapien pellentesque habitant morbi tristique senectus et netus. In egestas erat imperdiet sed. Pellentesque dignissim enim sit amet venenatis urna cursus eget. Dignissim suspendisse in est ante in nibh mauris cursus mattis.'
                    }
                    <br />
                    <br />
                    {
                        'Quis hendrerit dolor magna eget. Metus vulputate eu scelerisque felis imperdiet proin. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum est. At volutpat diam ut venenatis tellus in metus. Arcu non sodales neque sodales ut etiam sit amet nisl. Ac tortor dignissim convallis aenean et tortor at. Cursus turpis massa tincidunt dui ut ornare lectus sit. In nibh mauris cursus mattis molestie. Elementum eu facilisis sed odio morbi quis commodo odio aenean. Urna et pharetra pharetra massa massa ultricies mi quis hendrerit.'
                    }
                    <br />
                    <br />
                    {
                        'Volutpat ac tincidunt vitae semper quis lectus nulla at. Consectetur purus ut faucibus pulvinar elementum. Nullam ac tortor vitae purus. Ornare arcu dui vivamus arcu felis. Volutpat consequat mauris nunc congue nisi. Purus sit amet volutpat consequat mauris nunc congue nisi vitae. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Ac turpis egestas maecenas pharetra. Nisl purus in mollis nunc sed id. Scelerisque in dictum non consectetur a. A pellentesque sit amet porttitor eget dolor morbi non arcu.'
                    }
                    <br />
                    <br />
                    {
                        'Id diam maecenas ultricies mi eget mauris pharetra et. Nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Fames ac turpis egestas maecenas pharetra convallis. Accumsan lacus vel facilisis volutpat est velit egestas dui id. A condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Interdum posuere lorem ipsum dolor sit amet. Integer quis auctor elit sed vulputate mi sit. In eu mi bibendum neque egestas. Senectus et netus et malesuada fames. Ut aliquam purus sit amet luctus. Nunc pulvinar sapien et ligula ullamcorper. Et ultrices neque ornare aenean euismod elementum nisi. Faucibus et molestie ac feugiat sed. Sem nulla pharetra diam sit. Amet est placerat in egestas erat.'
                    }
                    <br />
                    <br />
                    {
                        'Ligula ullamcorper malesuada proin libero nunc consequat. Eleifend donec pretium vulputate sapien nec sagittis. Tincidunt ornare massa eget egestas purus viverra. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Leo in vitae turpis massa. Sit amet risus nullam eget felis eget nunc. Ultrices dui sapien eget mi. Tortor aliquam nulla facilisi cras fermentum odio. A iaculis at erat pellentesque adipiscing commodo elit at. Ultrices eros in cursus turpis massa. Sed felis eget velit aliquet sagittis id consectetur purus. Blandit turpis cursus in hac.'
                    }
                    <br />
                    <br />
                    {
                        'Amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Lectus vestibulum mattis ullamcorper velit sed. Neque egestas congue quisque egestas diam in. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Blandit volutpat maecenas volutpat blandit aliquam etiam. Non tellus orci ac auctor augue mauris augue. Pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu. Hac habitasse platea dictumst quisque sagittis purus sit amet. Sed sed risus pretium quam vulputate. Donec ac odio tempor orci dapibus ultrices in. Viverra maecenas accumsan lacus vel. Cras ornare arcu dui vivamus. Massa tincidunt dui ut ornare lectus sit amet est. Tortor at auctor urna nunc. Ante metus dictum at tempor commodo ullamcorper a lacus. Quis commodo odio aenean sed adipiscing diam donec adipiscing tristique. Non pulvinar neque laoreet suspendisse interdum consectetur libero id. Diam ut venenatis tellus in metus.'
                    }
                    <br />
                    <br />
                    {
                        'Porta nibh venenatis cras sed felis eget velit. Aliquet enim tortor at auctor urna nunc. Cras sed felis eget velit aliquet sagittis id consectetur. Nam at lectus urna duis convallis convallis. Aliquet nec ullamcorper sit amet risus. Orci ac auctor augue mauris. Nisl condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Orci a scelerisque purus semper eget. Praesent semper feugiat nibh sed pulvinar. Fusce id velit ut tortor pretium. Donec enim diam vulputate ut pharetra sit amet aliquam. Egestas fringilla phasellus faucibus scelerisque eleifend. Nunc lobortis mattis aliquam faucibus purus in massa.'
                    }
                    <br />
                    <br />
                    {
                        'Pharetra convallis posuere morbi leo urna molestie. Quam pellentesque nec nam aliquam sem et tortor. Ultricies lacus sed turpis tincidunt id. Purus viverra accumsan in nisl nisi scelerisque eu ultrices. Quis vel eros donec ac odio. Quam id leo in vitae turpis massa sed. Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum. Felis eget velit aliquet sagittis id consectetur purus ut faucibus. Euismod nisi porta lorem mollis aliquam ut. Id venenatis a condimentum vitae. Viverra ipsum nunc aliquet bibendum enim. Montes nascetur ridiculus mus mauris vitae ultricies leo integer. Dignissim suspendisse in est ante in nibh mauris.'
                    }
                    <br />
                    <br />
                    {
                        'Iaculis nunc sed augue lacus viverra vitae congue. Elit ullamcorper dignissim cras tincidunt lobortis feugiat. Pretium viverra suspendisse potenti nullam. Dignissim sodales ut eu sem. At augue eget arcu dictum varius. Blandit libero volutpat sed cras. Vivamus at augue eget arcu. Nec sagittis aliquam malesuada bibendum arcu vitae. Eleifend donec pretium vulputate sapien nec sagittis. Eu augue ut lectus arcu bibendum at. Urna molestie at elementum eu facilisis sed. Maecenas pharetra convallis posuere morbi leo. Velit egestas dui id ornare arcu odio ut sem nulla. Proin sed libero enim sed.'
                    }
                    <br />
                    <br />
                    {
                        'Eget nulla facilisi etiam dignissim. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Et malesuada fames ac turpis egestas integer eget aliquet. Elit eget gravida cum sociis natoque penatibus et magnis dis. Venenatis cras sed felis eget. Risus quis varius quam quisque id diam vel. Augue interdum velit euismod in pellentesque massa placerat duis ultricies. Nam libero justo laoreet sit. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Nisl purus in mollis nunc sed id semper risus in.'
                    }
                    <br />
                    <br />
                    {
                        'Dictum non consectetur a erat nam at lectus. Nunc non blandit massa enim nec dui nunc mattis enim. Ornare suspendisse sed nisi lacus sed viverra tellus in hac. Morbi tristique senectus et netus et malesuada. Metus vulputate eu scelerisque felis. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac. A cras semper auctor neque vitae. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Sem et tortor consequat id porta nibh venenatis. Consequat nisl vel pretium lectus quam id leo in vitae. Vel pharetra vel turpis nunc eget lorem. Etiam tempor orci eu lobortis elementum nibh tellus molestie nunc. In cursus turpis massa tincidunt dui ut ornare lectus. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Consequat mauris nunc congue nisi vitae suscipit. Cursus sit amet dictum sit amet justo donec. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras. Justo laoreet sit amet cursus sit amet.'
                    }
                    <br />
                    <br />
                    {
                        'Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Pharetra et ultrices neque ornare aenean euismod. Vestibulum rhoncus est pellentesque elit ullamcorper. Aliquam sem et tortor consequat id. Neque sodales ut etiam sit amet nisl purus. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor. Nisl vel pretium lectus quam id. Enim nulla aliquet porttitor lacus luctus accumsan tortor. Quis enim lobortis scelerisque fermentum dui faucibus in ornare. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Maecenas sed enim ut sem viverra aliquet eget. Facilisis mauris sit amet massa vitae tortor. Id aliquet risus feugiat in ante metus. Volutpat diam ut venenatis tellus in metus vulputate eu.'
                    }
                    <br />
                    <br />
                    {
                        'Elit ullamcorper dignissim cras tincidunt. Vitae justo eget magna fermentum iaculis. Bibendum enim facilisis gravida neque convallis a cras semper. Ultrices dui sapien eget mi proin sed libero enim. A lacus vestibulum sed arcu non odio euismod lacinia at. Urna neque viverra justo nec ultrices. Sit amet consectetur adipiscing elit pellentesque habitant morbi. Eu augue ut lectus arcu bibendum at varius vel pharetra. Egestas quis ipsum suspendisse ultrices gravida. Mi bibendum neque egestas congue quisque egestas. Ornare arcu odio ut sem nulla pharetra diam sit. Dolor sit amet consectetur adipiscing elit pellentesque. Tristique magna sit amet purus gravida quis blandit. Vehicula ipsum a arcu cursus vitae. Vulputate dignissim suspendisse in est ante in.'
                    }
                    <br />
                    <br />
                    {
                        'Ornare arcu odio ut sem nulla pharetra diam sit. Erat nam at lectus urna duis convallis convallis. Non nisi est sit amet facilisis magna etiam. Lacus vel facilisis volutpat est. Porta nibh venenatis cras sed felis eget velit. Aliquam id diam maecenas ultricies mi. Enim nunc faucibus a pellentesque sit amet. Velit scelerisque in dictum non consectetur a erat. Vulputate odio ut enim blandit volutpat maecenas. Diam maecenas ultricies mi eget. Sed arcu non odio euismod lacinia. Eget felis eget nunc lobortis mattis aliquam. Semper eget duis at tellus at urna condimentum mattis pellentesque. Sed enim ut sem viverra aliquet eget sit amet tellus. Velit scelerisque in dictum non consectetur a erat nam at. Nisl vel pretium lectus quam id leo. Nullam eget felis eget nunc lobortis mattis aliquam.'
                    }
                    <br />
                    <br />
                    {
                        'Tellus rutrum tellus pellentesque eu tincidunt tortor. Tellus in metus vulputate eu scelerisque felis imperdiet proin. Vestibulum lorem sed risus ultricies tristique nulla aliquet enim. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Sed egestas egestas fringilla phasellus faucibus. Adipiscing at in tellus integer feugiat scelerisque varius morbi enim. Suspendisse sed nisi lacus sed. Volutpat est velit egestas dui id ornare arcu odio ut. Lectus urna duis convallis convallis tellus. Fringilla urna porttitor rhoncus dolor purus. Condimentum vitae sapien pellentesque habitant morbi. Adipiscing elit pellentesque habitant morbi tristique senectus et netus. Facilisi nullam vehicula ipsum a. Egestas sed sed risus pretium quam vulputate. Convallis a cras semper auctor neque vitae. Proin sed libero enim sed faucibus turpis. Mattis enim ut tellus elementum sagittis vitae et leo. Amet aliquam id diam maecenas ultricies mi eget mauris pharetra. Ipsum suspendisse ultrices gravida dictum fusce ut placerat.'
                    }
                    <br />
                    <br />
                    {
                        'Interdum varius sit amet mattis vulputate enim. Gravida dictum fusce ut placerat orci nulla pellentesque dignissim enim. Fames ac turpis egestas sed tempus urna et pharetra pharetra. Pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum odio. Lobortis scelerisque fermentum dui faucibus in ornare quam viverra. Vel risus commodo viverra maecenas. Eget sit amet tellus cras adipiscing enim eu. Vestibulum lectus mauris ultrices eros in cursus. Tellus id interdum velit laoreet id donec ultrices tincidunt arcu. Semper eget duis at tellus at urna condimentum mattis.'
                    }
                    <br />
                    <br />
                    {
                        'Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Non nisi est sit amet facilisis magna. Augue neque gravida in fermentum et sollicitudin ac. Nulla pharetra diam sit amet nisl suscipit. Semper auctor neque vitae tempus quam pellentesque nec nam aliquam. Porttitor leo a diam sollicitudin tempor id. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit. Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis a. Non curabitur gravida arcu ac tortor. Convallis tellus id interdum velit laoreet id donec. Pretium fusce id velit ut tortor pretium viverra. Pulvinar pellentesque habitant morbi tristique senectus. Quisque non tellus orci ac auctor. Commodo odio aenean sed adipiscing diam.            '
                    }
                </>
            );
        }
    }

    return (
        <Miniature
            pageWidth={pageWidth}
            pageHeight={pageHeight}
            pageMeasure={pageMeasure}
            {...props}
            content={<div className={'content'}>{contents()}</div>}
            details={details}
        />
    );
}

TemplateMiniature.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pageWidth: PropTypes.number.isRequired,
    pageHeight: PropTypes.number.isRequired,
    pageMeasure: PropTypes.string.isRequired,
    showDetails: PropTypes.bool,
    onlyImages: PropTypes.bool,
    ...Miniature.propTypes,
};

export default TemplateMiniature;
