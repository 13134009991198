import FieldText from '../components/FieldText';
import FieldSelect from '../components/FieldSelect';
import FieldMeasure from '../components/FieldMeasure';
import { unmask } from '../components/MeasureInput';
import ButtonOrientation from '../components/ButtonOrientation';
import FieldInteger from '../components/FieldInteger';
import FieldCheckbox from '../components/FieldCheckbox';
import ButtonLock from '../components/ButtonLock';
import {
    DocumentTypeEnum,
    DocumentTypeEnumToString,
    MeasureEnum,
    MeasureEnumToString,
    OrientationEnum,
    RoleEnum,
} from 'plataforma-braille-common';
import { useState } from 'react';
import FieldShareDocument from '../components/FieldShareDocument';
import { enumToSelectOptions } from '../util/EnumToSelectOptions';
import { FieldsFontType } from '../edit-document/FieldsFontType';

/**
 * @param title {string}
 * @param formError {FormError}
 * @param formData {FormData}
 * @param updateFormData
 * @param updateBraillePageMargin
 * @param updateInkPageMargin
 * @param saveTemplateLoading
 * @param saveTemplate
 * @param saveDocumentLoading
 * @param createDocument
 * @return {JSX.Element}
 * @constructor
 */
function DocumentModalForm({
    title,
    formError,
    formData,
    updateFormData,
    updateBraillePageMargin,
    updateInkPageMargin,
    saveTemplateLoading,
    saveTemplate,
    saveDocumentLoading,
    createDocument,
}) {
    const [teamSelected, setTeamSelected] = useState(RoleEnum.LINEARIZATION);

    return (
        <form className={'condensed-controls'} aria-label={title}>
            <div className={'gd-inner'}>
                <div className={'gd-col gd-col-2--desktop'}>
                    <FieldSelect
                        // I18N
                        label={'Tipo'}
                        // I18N
                        placeholder={'Selecione o tipo de documento'}
                        options={enumToSelectOptions(
                            DocumentTypeEnum,
                            DocumentTypeEnumToString,
                        )}
                        required={true}
                        validationError={formError.type}
                        value={formData.type ?? '-1'}
                        onChange={(e) =>
                            updateFormData({ type: e.target.value })
                        }
                    />
                </div>
                <div className={'gd-col gd-col-3--desktop'}>
                    <FieldText
                        // I18N
                        label={'Nome do documento'}
                        // I18N
                        placeholder={'Nome do documento'}
                        validationError={formError.name}
                        required={true}
                        maxLength={255}
                        value={formData.name}
                        onChange={(e) =>
                            updateFormData({ name: e.target.value })
                        }
                    />
                </div>
            </div>
            <div className={'gd-inner'}>
                <div className={'gd-col gd-col-3--desktop'}>
                    <FieldSelect
                        // I18N
                        label={'Medida'}
                        // I18N
                        placeholder={'Selecione uma medida'}
                        options={enumToSelectOptions(
                            MeasureEnum,
                            MeasureEnumToString,
                        )}
                        required={true}
                        validationError={formError.pageMeasure}
                        value={formData.pageMeasure ?? '-1'}
                        onChange={(e) =>
                            updateFormData({
                                pageMeasure: e.target.value,
                                pageWidth: '',
                                pageHeight: '',
                            })
                        }
                    />
                </div>
                <div className={'gd-col gd-col-1--desktop'}>
                    <FieldMeasure
                        // I18N
                        label={'Largura'}
                        placeholder={
                            formData.pageMeasure === MeasureEnum.MILLIMETER
                                ? '000mm'
                                : '00cm'
                        }
                        measure={formData.pageMeasure ?? MeasureEnum.MILLIMETER}
                        required={true}
                        validationError={formError.pageWidth}
                        disabled={!formData.pageMeasure}
                        value={formData.pageWidth}
                        onChange={(e) =>
                            updateFormData({
                                pageWidth: unmask(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={'gd-col gd-col-1--desktop'}>
                    <FieldMeasure
                        // I18N
                        label={'Altura'}
                        placeholder={
                            formData.pageMeasure === MeasureEnum.MILLIMETER
                                ? '000mm'
                                : '00cm'
                        }
                        measure={formData.pageMeasure ?? MeasureEnum.MILLIMETER}
                        required={true}
                        validationError={formError.pageHeight}
                        disabled={!formData.pageMeasure}
                        value={formData.pageHeight}
                        onChange={(e) =>
                            updateFormData({
                                pageHeight: unmask(e.target.value),
                            })
                        }
                    />
                </div>
            </div>

            <div className={'gd-inner'}>
                <div
                    className={'gd-col gd-col-1--desktop gd-col--valign-bottom'}
                >
                    <div
                        className={`field-group ${formError.pageOrientation ? 'error' : ''}`}
                    >
                        <label className={'field-label'}>
                            {/*I18N*/}
                            {'Orientação'}
                        </label>
                        <div className={'orientation-controls'}>
                            <ButtonOrientation
                                orientation={OrientationEnum.PORTRAIT}
                                selected={
                                    formData.pageOrientation ===
                                    OrientationEnum.PORTRAIT
                                }
                                onClick={() =>
                                    updateFormData({
                                        pageOrientation:
                                            OrientationEnum.PORTRAIT,
                                    })
                                }
                            />
                            <ButtonOrientation
                                orientation={OrientationEnum.LANDSCAPE}
                                selected={
                                    formData.pageOrientation ===
                                    OrientationEnum.LANDSCAPE
                                }
                                onClick={() =>
                                    updateFormData({
                                        pageOrientation:
                                            OrientationEnum.LANDSCAPE,
                                    })
                                }
                            />
                        </div>
                        <div
                            id={'pageOrientationError'}
                            className={'validation-error'}
                        >
                            {formError.pageOrientation}
                        </div>
                    </div>
                </div>
                <div className={'gd-col gd-col-1--desktop'}>
                    <FieldInteger
                        // I18N
                        label={'Limite de pág.'}
                        required={true}
                        placeholder={'150'}
                        validationError={formError.pageLimit}
                        value={formData.pageLimit}
                        onChange={(e) =>
                            updateFormData({
                                pageLimit: unmask(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={'gd-col gd-col-1--desktop'} />
                <div className={'gd-col gd-col-1--desktop'}>
                    <FieldCheckbox
                        // I18N
                        label={'Interponto'}
                        validationError={formError.interPoint}
                        inputs={(() => [
                            {
                                // I18N
                                label: 'Ativar',
                                onChange: (e) =>
                                    updateFormData({
                                        interPoint: e.target['checked'],
                                    }),
                                checked: formData.interPoint,
                            },
                        ])()}
                    />
                </div>
            </div>

            {formData.type !== DocumentTypeEnum.IMAGE_DESCRIPTION && (
                <section>
                    <h4>
                        {/*I18N*/}
                        {'Propriedades Braille'}
                    </h4>

                    <div className={'gd-inner'}>
                        <div className={'gd-col gd-col-2--desktop'}>
                            <fieldset>
                                <legend>{'Grade braille'}</legend>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldInteger
                                            // I18N
                                            label={'Caracteres'}
                                            placeholder={'00'}
                                            required={true}
                                            validationError={
                                                formError.brailleCellColCount
                                            }
                                            value={formData.brailleCellColCount}
                                            onChange={(e) =>
                                                updateFormData({
                                                    brailleCellColCount: unmask(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldInteger
                                            // I18N
                                            label={'Linhas'}
                                            placeholder={'00'}
                                            required={true}
                                            validationError={
                                                formError.brailleCellRowCount
                                            }
                                            value={formData.brailleCellRowCount}
                                            onChange={(e) =>
                                                updateFormData({
                                                    brailleCellRowCount: unmask(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div className={'gd-inner'}>
                        <div className={'gd-col gd-col-5--desktop'}>
                            <fieldset>
                                <legend>
                                    {/*I18N*/}
                                    {'Margens em braille'}
                                </legend>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Esquerda'}
                                            placeholder={'000mm'}
                                            measure={MeasureEnum.MILLIMETER}
                                            required={true}
                                            validationError={
                                                formError.braillePageMarginLeft
                                            }
                                            value={
                                                formData.braillePageMarginLeft
                                            }
                                            onChange={(e) => {
                                                if (
                                                    formData.braillePageMarginLocked
                                                ) {
                                                    updateBraillePageMargin(
                                                        e.target.value,
                                                    );
                                                } else {
                                                    updateFormData({
                                                        braillePageMarginLeft:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Superior'}
                                            placeholder={'000mm'}
                                            measure={MeasureEnum.MILLIMETER}
                                            required={true}
                                            validationError={
                                                formError.braillePageMarginTop
                                            }
                                            value={
                                                formData.braillePageMarginTop
                                            }
                                            onChange={(e) => {
                                                if (
                                                    formData.braillePageMarginLocked
                                                ) {
                                                    updateBraillePageMargin(
                                                        e.target.value,
                                                    );
                                                } else {
                                                    updateFormData({
                                                        braillePageMarginTop:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Direita'}
                                            placeholder={'000mm'}
                                            measure={MeasureEnum.MILLIMETER}
                                            required={true}
                                            validationError={
                                                formError.braillePageMarginRight
                                            }
                                            value={
                                                formData.braillePageMarginRight
                                            }
                                            onChange={(e) => {
                                                if (
                                                    formData.braillePageMarginLocked
                                                ) {
                                                    updateBraillePageMargin(
                                                        e.target.value,
                                                    );
                                                } else {
                                                    updateFormData({
                                                        braillePageMarginRight:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Inferior'}
                                            placeholder={'000mm'}
                                            measure={MeasureEnum.MILLIMETER}
                                            required={true}
                                            validationError={
                                                formError.braillePageMarginBottom
                                            }
                                            value={
                                                formData.braillePageMarginBottom
                                            }
                                            onChange={(e) => {
                                                if (
                                                    formData.braillePageMarginLocked
                                                ) {
                                                    updateBraillePageMargin(
                                                        e.target.value,
                                                    );
                                                } else {
                                                    updateFormData({
                                                        braillePageMarginBottom:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <div>
                                            <label>&nbsp;</label>{' '}
                                            {/* just to keep control aligned */}
                                            <ButtonLock
                                                locked={
                                                    formData.braillePageMarginLocked
                                                }
                                                onClick={() => {
                                                    updateFormData({
                                                        braillePageMarginLocked:
                                                            !formData.braillePageMarginLocked,
                                                    });
                                                    updateBraillePageMargin(
                                                        formData.braillePageMarginLeft +
                                                            'mm',
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </section>
            )}
            <section>
                <h4>
                    {/*I18N*/}
                    {'Propriedades tinta'}
                </h4>

                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <fieldset>
                            <legend>{'Margens tinta'}</legend>
                            <div className={'gd-inner'}>
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <FieldMeasure
                                        // I18N
                                        label={'Esquerda'}
                                        placeholder={'000mm'}
                                        measure={MeasureEnum.MILLIMETER}
                                        required={true}
                                        validationError={
                                            formError.inkPageMarginLeft
                                        }
                                        value={formData.inkPageMarginLeft}
                                        onChange={(e) => {
                                            if (formData.inkPageMarginLocked) {
                                                updateInkPageMargin(
                                                    e.target.value,
                                                );
                                            } else {
                                                updateFormData({
                                                    inkPageMarginLeft: unmask(
                                                        e.target.value,
                                                    ),
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <FieldMeasure
                                        // I18N
                                        label={'Superior'}
                                        placeholder={'000mm'}
                                        measure={MeasureEnum.MILLIMETER}
                                        required={true}
                                        validationError={
                                            formError.inkPageMarginTop
                                        }
                                        value={formData.inkPageMarginTop}
                                        onChange={(e) => {
                                            if (formData.inkPageMarginLocked) {
                                                updateInkPageMargin(
                                                    e.target.value,
                                                );
                                            } else {
                                                updateFormData({
                                                    inkPageMarginTop: unmask(
                                                        e.target.value,
                                                    ),
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <FieldMeasure
                                        // I18N
                                        label={'Direita'}
                                        id={'inkPageMarginRight'}
                                        placeholder={'000mm'}
                                        measure={MeasureEnum.MILLIMETER}
                                        required={true}
                                        validationError={
                                            formError.inkPageMarginRight
                                        }
                                        value={formData.inkPageMarginRight}
                                        onChange={(e) => {
                                            if (formData.inkPageMarginLocked) {
                                                updateInkPageMargin(
                                                    e.target.value,
                                                );
                                            } else {
                                                updateFormData({
                                                    inkPageMarginRight: unmask(
                                                        e.target.value,
                                                    ),
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <FieldMeasure
                                        // I18N
                                        label={'Inferior'}
                                        placeholder={'000mm'}
                                        measure={MeasureEnum.MILLIMETER}
                                        required={true}
                                        validationError={
                                            formError.inkPageMarginBottom
                                        }
                                        value={formData.inkPageMarginBottom}
                                        onChange={(e) => {
                                            if (formData.inkPageMarginLocked) {
                                                updateInkPageMargin(
                                                    e.target.value,
                                                );
                                            } else {
                                                updateFormData({
                                                    inkPageMarginBottom: unmask(
                                                        e.target.value,
                                                    ),
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <div>
                                        <label>&nbsp;</label>{' '}
                                        {/* just to keep control aligned */}
                                        <ButtonLock
                                            locked={
                                                formData.inkPageMarginLocked
                                            }
                                            onClick={() => {
                                                updateFormData({
                                                    inkPageMarginLocked:
                                                        !formData.inkPageMarginLocked,
                                                });
                                                updateInkPageMargin(
                                                    formData.inkPageMarginLeft +
                                                        'mm',
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <fieldset>
                            <legend>
                                {/*I18N*/}
                                {'Fonte'}
                            </legend>
                            <div className={'gd-inner'}>
                                <FieldsFontType
                                    formData={formData}
                                    formError={formError}
                                    onChange={(formData) =>
                                        updateFormData(formData)
                                    }
                                />
                                <div className={'gd-col gd-col-1--desktop'}>
                                    <FieldMeasure
                                        // I18N
                                        label={'Altura da linha'}
                                        className={'no-wrap-label'}
                                        placeholder={'24pt'}
                                        measure={'pt'}
                                        required={true}
                                        validationError={
                                            formError.inkPageLineHeight
                                        }
                                        value={formData.inkPageLineHeight}
                                        onChange={(e) => {
                                            updateFormData({
                                                inkPageLineHeight: unmask(
                                                    e.target.value,
                                                ),
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </section>

            <section>
                <h4>
                    {/*I18N*/}
                    {'Hifenização'}
                </h4>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-2--desktop'}>
                                <FieldCheckbox
                                    // I18N
                                    inputs={(() => [
                                        {
                                            // I18N
                                            label: 'Ativar',
                                            checked: formData.hyphenation,
                                            onChange: (e) =>
                                                updateFormData({
                                                    hyphenation:
                                                        e.target.checked,
                                                    hyphenationLettersMin: null,
                                                    hyphenationSyllablesMin:
                                                        null,
                                                    hyphenationParagraphMax:
                                                        null,
                                                }),
                                        },
                                    ])()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'gd-col gd-col-5--desktop'}>
                    <div className={'gd-inner'}>
                        <div className={'gd-inner'}>
                            <div
                                className={
                                    'gd-col gd-col-1--desktop gd-col--valign-bottom'
                                }
                            >
                                <FieldInteger
                                    // I18N
                                    label={'Quantidade mínima de letras'}
                                    disabled={!formData.hyphenation}
                                    placeholder={'0'}
                                    required={true}
                                    validationError={
                                        formError.hyphenationLettersMin
                                    }
                                    value={formData.hyphenationLettersMin}
                                    onChange={(e) =>
                                        updateFormData({
                                            hyphenationLettersMin: unmask(
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />
                            </div>
                            <div
                                className={
                                    'gd-col gd-col-1--desktop gd-col--valign-bottom'
                                }
                            >
                                <FieldInteger
                                    // I18N
                                    label={'Quantidade mínima de sílabas'}
                                    disabled={!formData.hyphenation}
                                    placeholder={'0'}
                                    required={true}
                                    validationError={
                                        formError.hyphenationSyllablesMin
                                    }
                                    value={formData.hyphenationSyllablesMin}
                                    onChange={(e) =>
                                        updateFormData({
                                            hyphenationSyllablesMin: unmask(
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />
                            </div>
                            <div
                                className={
                                    'gd-col gd-col-1--desktop gd-col--valign-bottom'
                                }
                            >
                                <FieldInteger
                                    // I18N
                                    label={'Quantidade máxima por parágrafo'}
                                    disabled={!formData.hyphenation}
                                    placeholder={'0'}
                                    required={true}
                                    validationError={
                                        formError.hyphenationParagraphMax
                                    }
                                    value={formData.hyphenationParagraphMax}
                                    onChange={(e) =>
                                        updateFormData({
                                            hyphenationParagraphMax: unmask(
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />
                            </div>
                            <div
                                className={
                                    'gd-col gd-col-1--desktop gd-col--valign-bottom'
                                }
                            >
                                <FieldInteger
                                    // I18N
                                    label={'Distância entre hífens'}
                                    disabled={!formData.hyphenation}
                                    placeholder={'0'}
                                    required={true}
                                    validationError={
                                        formError.hyphenationDistanceBetweenHyphens
                                    }
                                    value={
                                        formData.hyphenationDistanceBetweenHyphens
                                    }
                                    onChange={(e) =>
                                        updateFormData({
                                            hyphenationDistanceBetweenHyphens:
                                                unmask(e.target.value),
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <h4>{'Compartilhar'}</h4>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <FieldShareDocument
                            teamSelected={teamSelected}
                            setTeamSelected={setTeamSelected}
                            userSelected={formData.userWithAccess}
                            setUserSelected={(userSelected) => {
                                updateFormData({
                                    userWithAccess: userSelected,
                                });
                            }}
                        />
                    </div>
                </div>
            </section>

            <section>
                {/*I18N*/}
                <h4>{'Predefinição'}</h4>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-3--desktop'}>
                        <div>
                            <FieldCheckbox
                                inputs={(() => [
                                    {
                                        label: 'Salvar como predefinição',
                                        checked: formData.templateCreate,
                                        onChange: () =>
                                            updateFormData({
                                                templateCreate:
                                                    !formData.templateCreate,
                                                templateName: '',
                                            }),
                                    },
                                ])()}
                            />

                            <FieldText
                                className={'transparent'}
                                // I18N
                                placeholder={
                                    'Digite um nome para a predefinição'
                                }
                                style={{
                                    display: formData.templateCreate
                                        ? ''
                                        : 'none',
                                }}
                                maxLength={255}
                                validationError={formError.templateName}
                                value={formData.templateName}
                                onChange={(e) =>
                                    updateFormData({
                                        templateName: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div className={'gd-inner'}>
                <div className={'gd-col gd-col-5--desktop form-controls'}>
                    <button
                        style={{
                            display: formData.templateCreate ? '' : 'none',
                        }}
                        className={`${saveTemplateLoading ? 'loading' : ''}`}
                        onClick={saveTemplate}
                    >
                        {'Salvar modificação na predefinição'}
                    </button>
                    <button
                        className={`primary ${saveDocumentLoading ? 'loading' : ''} ${!createDocument ? 'disabled' : ''}`}
                        onClick={
                            createDocument
                                ? createDocument
                                : (e) => e.preventDefault()
                        }
                    >
                        {'Criar documento'}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default DocumentModalForm;
