export enum DocumentTypeEnum {
    BRAILLE = "BRAILLE",
    IMAGE_DESCRIPTION = "IMAGE_DESCRIPTION",
}

export type DocumentTypeEnumValue =
    (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

export function DocumentTypeEnumToString(key: string): string {
    const documentType = DocumentTypeEnum[key];
    switch (documentType) {
        // I18N
        case DocumentTypeEnum.BRAILLE:
            return "Braille";
        // I18N
        case DocumentTypeEnum.IMAGE_DESCRIPTION:
            return "Descrição de imagens";
        default:
            throw new Error("Invalid enum value");
    }
}
