import {
    EditorCloseStep,
    EditorCloseStep2x,
    EditorPdfFile,
    EditorPdfFile2x,
    EditorRename,
    EditorRename2x,
    EditorSendTo,
    EditorSendTo2x,
    EditorShare,
    EditorShare2x,
    EditorTxtFile,
    EditorTxtFile2x,
} from '../../../../components/images';
import {
    userCanChangeDocumentStatus,
    userCanExport,
    userCanImportPdf,
    userCanRemove,
    userCanRenameDocument,
    userCanSave,
    userCanShareDocument,
} from '../../../UserPermissions';
import { getVersion } from '../../../../version';
import { addIcon } from './MenuModule';
import {
    DocumentStatusEnumToString,
    getDocumentStatusArray,
} from 'plataforma-braille-common';
import { getBrailleDocument } from '../core/EditorUtil';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function createFileMenu(editor, params) {
    addIcon(editor, 'customEditorRename', EditorRename, EditorRename2x);
    addIcon(
        editor,
        'customEditorUserWithPermission',
        EditorShare,
        EditorShare2x,
    );
    addIcon(editor, 'customEditorSendTo', EditorSendTo, EditorSendTo2x);
    addIcon(
        editor,
        'customEditorCloseStep',
        EditorCloseStep,
        EditorCloseStep2x,
    );
    addIcon(editor, 'customEditorTxtFile', EditorTxtFile, EditorTxtFile2x);
    addIcon(editor, 'customEditorPdfFile', EditorPdfFile, EditorPdfFile2x);

    if (userCanSave(params.readOnly)) {
        editor.addShortcut(
            'ctrl + s',
            // I18N
            'Salvar',
            params.saveDocumentAction,
        );
        editor.ui.registry.addMenuItem('customFileMenuSave', {
            // I18N
            text: 'Salvar',
            shortcut: 'ctrl + s',
            icon: 'save',
            onAction: params.saveDocumentAction,
        });
    }

    if (
        userCanRemove(
            params.isAdmin,
            params.isEvaluator,
            params.isModerator,
            params.isOwner,
        )
    ) {
        editor.ui.registry.addMenuItem('customFileMenuRemove', {
            // I18N
            text: 'Remover',
            icon: 'remove',
            onAction: params.removeDocumentAction,
            onSetup: (api) => {
                api.setEnabled(!getBrailleDocument(editor).lockedByUser);
            },
        });
    }

    if (
        userCanImportPdf(
            params.isAdmin,
            params.isEvaluator,
            params.isModerator,
            params.isOwner,
            params.readOnly,
            params.userDocumentRoles,
        )
    ) {
        editor.ui.registry.addMenuItem('customFileMenuImportPdf', {
            // I18N
            text: 'Importar PDF',
            shortcut: 'ctrl + shift + i',
            icon: 'upload',
            onAction: params.importPdfAction,
        });
    }

    if (
        userCanRenameDocument(
            params.isAdmin,
            params.isEvaluator,
            params.isModerator,
            params.isOwner,
            params.readOnly,
            params.userDocumentRoles,
        )
    ) {
        editor.ui.registry.addMenuItem('customFileMenuRename', {
            // I18N
            text: 'Renomear',
            onAction: params.renameAction,
            icon: 'customEditorRename',
        });
    }

    if (
        userCanChangeDocumentStatus(
            params.isAdmin,
            params.isEvaluator,
            params.isModerator,
        )
    ) {
        editor.ui.registry.addNestedMenuItem('customFileMenuSendTo', {
            // I18N
            text: 'Enviar para',
            icon: 'customEditorSendTo',
            getSubmenuItems: () => {
                const document = getBrailleDocument(editor);
                let items = getDocumentStatusArray();
                return items.map((item) => {
                    return {
                        type: 'togglemenuitem',
                        text: DocumentStatusEnumToString(item),
                        onAction: () => params.changeDocumentStatusAction(item),
                        onSetup: (api) => {
                            api.setActive(document.status === item);
                        },
                    };
                });
            },
            onSetup: (api) => {
                api.setEnabled(!getBrailleDocument(editor).lockedByUser);
            },
        });
    } else {
        if (!params.readOnly) {
            editor.ui.registry.addMenuItem('customFileMenuCloseStep', {
                // I18N
                text: 'Encerrar etapa',
                icon: 'customEditorCloseStep',
                onAction: () => params.changeDocumentStatusAction(),
            });
        }
    }

    if (
        userCanShareDocument(
            params.isAdmin,
            params.isEvaluator,
            params.isModerator,
            params.isPrintShop,
            params.userDocumentRoles,
            params.status,
        )
    ) {
        editor.ui.registry.addMenuItem('customFileMenuUserWithAccess', {
            // I18N
            text: 'Compartilhar',
            onAction: params.userWithAccessAction,
            icon: 'customEditorUserWithPermission',
        });
    }

    if (
        userCanExport(
            params.isAdmin,
            params.isEvaluator,
            params.isPrintShop,
            params.userDocumentRoles,
        )
    ) {
        editor.ui.registry.addNestedMenuItem('customFileMenuExport', {
            // I18N
            text: 'Exportar',
            icon: 'export',
            getSubmenuItems: function () {
                return [
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Exportar TXT',
                        icon: 'customEditorTxtFile',
                        onAction: params.exportTxtAction,
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Exportar PDF',
                        icon: 'customEditorPdfFile',
                        onAction: params.exportPdfAction,
                    },
                ];
            },
        });
    }

    if (params.isAdmin) {
        editor.ui.registry.addMenuItem('customUnlockDocument', {
            // I18N
            text: 'Desbloquear documento para edição',
            icon: 'unlock',
            onSetup: (api) => {
                api.setEnabled(getBrailleDocument(editor).lockedByUser);
            },
            onAction: params.forceUnlockDocumentAction,
        });
    }

    editor.ui.registry.addMenuItem('customFileVersion', {
        // I18N
        text: `Versão ${getVersion()}`,
        enabled: false,
    });
    editor.ui.registry.addMenuItem('customFileMenuExit', {
        // I18N
        text: 'Sair',
        icon: 'home',
        onAction: params.exitAction,
    });
}
