import {
    isInsideEditorBrailleView,
    isMultipleSelection,
    preventScroll,
} from './core/EditorUtil';

import { isDebugEnabled } from './core/CoreModule';

export class MouseModule {
    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[MouseModule]', ...data);
        }
    }

    mouseUp() {
        if (isMultipleSelection(this.editor)) return;
        this.editor.custom.keyboardModule?.fixCaretPosition();
    }

    /**
     * @param e {DragEvent}
     */
    dragStart(e) {
        if (isInsideEditorBrailleView(e.srcElement)) {
            this.debug('Drag of editor braille view aborted.');
            e.preventDefault();
        }
    }

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    install() {
        this.editor.on('mouseUp', () => this.mouseUp());
        const self = this;
        this.editor.on('DragStart', function (e) {
            self.dragStart(e);
        });
        /**
         * This avoids a strange behavior: when cursor is inside an element and
         * clicks outside the page, the page scrolls at start
         */
        this.editor.getDoc().addEventListener('mousedown', (e) => {
            /**
             * @type {HTMLElement | null}
             */
            const targetElement = e.target;
            if (targetElement?.tagName === 'HTML') {
                e.preventDefault();
                preventScroll(this.editor);
            }
        });
    }
}
