import './Miniature.scss';
import nextId from 'react-id-generator';
import { measureInPx } from '../util/Measure';
import { OrientationEnum } from 'plataforma-braille-common';
import {
    MiniatureMenu,
    MiniatureMenu2x,
    MiniatureExpand,
    MiniatureExpand2x,
} from '../components/images';
import PropTypes from 'prop-types';
import { FontNameEnum as InkFontTypeEnum } from '../edit-document/FieldsFontType';
function Miniature({
    pageWidth,
    pageHeight,
    pageMeasure,
    pageOrientation,
    inkFontType,
    inkFontSize,
    inkPageMarginLeft,
    inkPageMarginTop,
    inkPageMarginRight,
    inkPageMarginBottom,
    content,
    details,
    maxWidth,
    onClick,
    onDoubleClick,
    inkPageLineHeight,
    globalMaxWidth,
    className,
    onExpandClick,
    onShowMenuClick,
}) {
    if (!maxWidth || isNaN(maxWidth)) {
        throw Error('Max width not informed.');
    }

    if (pageOrientation === OrientationEnum.LANDSCAPE) {
        const width = pageWidth;
        // noinspection JSSuspiciousNameCombination
        pageWidth = pageHeight;
        // noinspection JSSuspiciousNameCombination
        pageHeight = width;
    }

    const id = nextId('miniature');
    let miniatureWidth;
    let miniatureHeight;

    const pageWidthPx = measureInPx(`${pageWidth}${pageMeasure}`);
    const pageHeightPx = measureInPx(`${pageHeight}${pageMeasure}`);

    globalMaxWidth = globalMaxWidth ?? pageWidthPx;
    let globalReason = pageWidthPx / globalMaxWidth;
    if (globalReason < 0.5) {
        globalReason = 0.5; // avoid very small miniatures
    } else if (globalReason > 1 || isNaN(globalReason)) {
        globalReason = 1;
    }

    maxWidth = maxWidth * globalReason;

    let reason = maxWidth / pageWidthPx;

    const r = pageHeightPx / pageWidthPx;
    miniatureWidth = pageWidthPx;
    miniatureHeight = miniatureWidth * r;

    const maxMiniatureHeight = maxWidth * 1.5;
    if (miniatureHeight * reason > maxMiniatureHeight) {
        reason = maxMiniatureHeight / miniatureHeight;
    }

    miniatureWidth = miniatureWidth * reason + 'px';
    miniatureHeight = miniatureHeight * reason + 'px';

    // same as css
    let fontFamily;
    let fontWeight;
    switch (inkFontType) {
        default:
        case InkFontTypeEnum.DEJAVU_SANS:
            fontFamily = 'PB DeJavu Sans';
            fontWeight = 'normal';
            break;
        case InkFontTypeEnum.DEJAVU_SANS_BOLD:
            fontFamily = 'PB DeJavu Sans';
            fontWeight = 'bold';
            break;
        case InkFontTypeEnum.NIMBUS_ROMAN:
            fontFamily = 'PB Nimbus Roman';
            fontWeight = 'normal';
            break;
    }

    const fontSize = inkFontSize;
    const paddingLeft = inkPageMarginLeft + 'mm';
    const paddingTop = inkPageMarginTop + 'mm';
    const paddingRight = inkPageMarginRight + 'mm';
    const paddingBottom = inkPageMarginBottom + 'mm';
    const lineHeight = `${inkPageLineHeight}px`;

    let display = undefined;
    if (pageHeight === 0 || pageWidth === 0) {
        display = 'none';
    }

    return (
        <div
            id={id}
            className={`document-miniature ${className ? className : ''}`}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
        >
            <div className={'miniature-container'}>
                {onExpandClick ? (
                    <button
                        className={'miniature-expand'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onExpandClick(e);
                        }}
                        onDoubleClick={(e) => e.preventDefault()}
                    >
                        <img
                            src={`${MiniatureExpand}`}
                            srcSet={`${MiniatureExpand} 1x, ${MiniatureExpand2x} 2x`}
                            // I18N
                            alt={'Exibir'}
                            onDragStart={(e) => e.preventDefault()}
                        />
                    </button>
                ) : (
                    <></>
                )}

                {onShowMenuClick && (
                    <button
                        className={'miniature-menu'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onShowMenuClick(e);
                        }}
                        onDoubleClick={(e) => e.preventDefault()}
                    >
                        <img
                            src={`${MiniatureMenu}`}
                            srcSet={`${MiniatureMenu} 1x, ${MiniatureMenu2x} 2x`}
                            // I18N
                            alt={'Opções'}
                            onDragStart={(e) => e.preventDefault()}
                        />
                    </button>
                )}

                <div
                    className={'miniature'}
                    style={{
                        width: miniatureWidth,
                        height: miniatureHeight,
                    }}
                >
                    <div
                        className={'miniature-content'}
                        style={{
                            fontFamily,
                            fontSize,
                            fontWeight,
                            borderLeft: `${paddingLeft} solid #fff`,
                            borderTop: `${paddingTop} solid #fff`,
                            borderRight: `${paddingRight} solid #fff`,
                            borderBottom: `${paddingBottom} solid #fff`,
                            transformOrigin: 'top left',
                            transform: `scale(${reason}) translateZ(0)`,
                            width: pageWidthPx,
                            height: pageHeightPx,
                            lineHeight: lineHeight,
                            display,
                        }}
                    >
                        {content}
                    </div>
                </div>
            </div>
            {details ? details() : <></>}
        </div>
    );
}

Miniature.propTypes = {
    pageWidth: PropTypes.number,
    pageHeight: PropTypes.number,
    pageMeasure: PropTypes.string,
    pageOrientation: PropTypes.string,
    inkFontType: PropTypes.string,
    inkFontSize: PropTypes.number,
    inkPageMarginLeft: PropTypes.number,
    inkPageMarginTop: PropTypes.number,
    inkPageMarginRight: PropTypes.number,
    inkPageMarginBottom: PropTypes.number,
    content: PropTypes.node,
    details: PropTypes.func,
    maxWidth: PropTypes.number,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    inkPageLineHeight: PropTypes.number,
    globalMaxWidth: PropTypes.number,
    className: PropTypes.string,
    onExpandClick: PropTypes.func,
    onShowMenuClick: PropTypes.func,
};

export default Miniature;
